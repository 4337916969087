.hiddenBr {
  display: none;
}
.descContainer p {
  color: #000;
}
.name {
  font-weight: 400;
}
.title {
  font-weight: 200;
}

.tileContainer {
  padding: 0;
  margin: 0;
  width: 100vw;
}
.homePageContent {
  overflow: hidden;
}

@media (max-width: 567px) {
  .descContainer p {
    font-size: 37px;
  }

}
@media (max-width: 702px) and (min-width: 300px) {
  .hiddenBr {
    display: block;
  }
}
@media (max-width: 767px) and (min-width: 568px) {
  .descContainer p {
    font-size: 45px;
  }
}
@media (min-width: 768px) {
  .tileContainer {
    height: 44.4444444vw;
  }
}
@media (max-width: 979px) and (min-width: 768px) {
  .descContainer p {
    font-size: 54px;
  }
}
@media (max-width: 1199px) and (min-width: 980px) {
  .descContainer p {
    font-size: 54px;
  }
}
@media screen and (min-width: 1200px) {
  .descContainer p {
    font-size: 58px;
  }
}