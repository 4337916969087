.aboutHeader p {
  font-weight: 400;
  color: #000;
}
.spacer {
  height: 1px;
  background-color: rgb(232, 232, 232);
  padding: 0px;
}
.projectDescWrapper .experienceSectionHeader {
  margin-bottom: 32px;
  word-wrap: break-word;
  color: #333;
  font-size: 21px;
  line-height: 33px;
  font-weight: 400 !important;
}
@media (max-width: 567px) {
  .aboutHeader {
    padding-top: 20px;

    p {
      font-size: 27px;
      line-height: 31px;
    }
  }
  .spacer {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .experienceSectionHeader {
    font-size: 17px;
    line-height: 29px;
  }
}
@media (max-width: 767px) and (min-width: 568px) {
  .aboutHeader {
    padding-top: 30px;

    p {
      font-size: 33px;
      line-height: 40px;
    }
  }
  .spacer {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .experienceSectionHeader {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (max-width: 979px) and (min-width: 768px) {
  .aboutHeader {
    padding-top: 45px;

    p {
      font-size: 38px;
      line-height: 46px;
    }
  }
  .spacer {
    margin-bottom: 70px;
    margin-top: 70px;
  }
}
@media (max-width: 1199px) and (min-width: 980px) {
  .aboutHeader {
    padding-top: 60px;

    p {
      font-size: 38px;
      line-height: 46px;
    }
  }
  .spacer {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .aboutHeader {
    padding-top: 90px;

    p {
      font-size: 42px;
      line-height: 50px;
    }
  }
  .spacer {
    margin-bottom: 100px;
    margin-top: 100px;
  }
  .experienceSectionHeader {
    font-size: 22px;
    line-height: 34px;
  }
}