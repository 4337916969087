.footerSpacer {
  height: 1px;
  background-color: rgb(232, 232, 232);
  padding: 0px;
}
form {
  input, textarea {
    box-sizing: border-box;
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 30px;
    width: 100%;
    background-color: #f9f9f9;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 0;
    font-size: 14px;
  }
  input:focus, textarea:focus {
    border-width: 0px;
  }
  ::placeholder {
    color: #333;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #333;
  }
  ::-ms-input-placeholder {
    color: #333;
  }
  button {
    font-size: 18px;
    line-height: 26px;
    border: none;
    font-weight: 700;
    color: #e42323;
  }
  button:hover {
    color: #333;
  }
}
.footer2Links {
  color: #e42323;
  display: block;
  transition: all .2s linear;
  font-weight: 400;
}
.footer2Links:hover {
  color: #333;
}
.footerTitleWrapper .footer2Title{
  color: #333;
  font-weight: 400;
}

.tooltip {
  height: 0;

  section {
    transform: translateY(-27px);
    position: relative;
    margin-bottom: 1vw;
    width: 100%;
    
    p {
      background: #FFF;
      color: #e42323;
      font-weight: 700;
      display: inline-block;
      padding: 2px 5px;
      width: auto;
      border: 2px solid #e42323;
      border-radius: 4px;
      margin-left: 10px;
    }

    .caret-up {
      position: absolute;
      top: -10px;
      left: 20px;
      color: #e42323;
      height: 15px;
    }

  }
}

.sliderPopup {
    width: auto;
    max-width: 100vw;
    position: fixed;
    bottom: -40.8px;
    left: 0;
    z-index: 1;
    background-color: #e42323;
    color: #fff;
    font-style: normal;
    padding: 10px 15px;
    overflow-y: hidden;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: cubic-bezier(0, 0.33, 0.67, 1);
    transition-timing-function: cubic-bezier(0, 0.33, 0.67, 1);
    cursor: pointer;
    p {
      line-height: 1.3;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      text-overflow: ellipsis;
    }
  }
.sliderPopup.clozed {
    bottom: 0;
}

@media only screen and (max-width: 450px) {
    .sliderPopup {
      width: 100vw;
    }
}

@media (max-width: 567px) {
  .footerSpacer {
    margin-bottom: 50px;
  }
  .footerWrapper {
    margin-bottom: 50px;
  }
  .footer2Links, .footerTitleWrapper .footer2Title {
    font-size: 16px;
    line-height: 28px;
  }
}
@media (max-width: 767px) and (min-width: 568px) {
  .footerSpacer {
    margin-bottom: 60px;
  }
  .footerWrapper {
    margin-bottom: 60px;
  }
  .footer2Links, .footerTitleWrapper .footer2Title {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (max-width: 979px) and (min-width: 768px) {
  .footerSpacer {
    margin-bottom: 70px;
  }
  .footerWrapper {
    margin-bottom: 70px;
  }
  .footer2Links, .footerTitleWrapper .footer2Title {
    font-size: 19px;
    line-height: 31px;
  }
}
@media (max-width: 1199px) and (min-width: 980px) {
  .footerSpacer {
    margin-bottom: 80px;
  }
  .footerWrapper {
    margin-bottom: 80px;
  }
  .footer2Links, .footerTitleWrapper .footer2Title {
    font-size: 19px;
    line-height: 31px;
  }
}
@media screen and (min-width: 1200px) {
  .footerSpacer {
    margin-bottom: 100px;
  }
  .footerWrapper {
    margin-bottom: 100px;
  }
  .footer2Links, .footerTitleWrapper .footer2Title {
    font-size: 20px;
    line-height: 32px;
  }
}