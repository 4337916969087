* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  width: 940px;
  margin: 0 auto !important;
}

.pageContent {
  margin-top: 70px;
}

@media (max-width: 567px) {
  .container {
    width: 90%;
    margin-left: 5%;
  }
  .hamburger {
    right: 5%;
  }
  .logo {
    left: 5%;
  }
  .hamburgerToggle {
    padding: 0 5%;
  }
  .headline {
    padding: 80px 0 110px;
    margin: 0;
  }
}
@media (max-width: 767px) and (min-width: 568px) {
  .container {
    width: 80%;
    margin-left: 10%;
  }
  .hamburger {
    right: 10%;
  }
  .logo {
    left: 10%;
  }
  .hamburgerToggle {
    padding: 0 10%;
  }
  .headline {
    padding: 80px 0 125px;
    margin: 0;
  }
}
@media (max-width: 979px) and (min-width: 768px) {
  .container {
    width: 724px;
  }
  .hamburger {
    right: calc((100vw - 724px)/2);
  }
  .hamburgerToggle {
    padding: 0 calc((100vw - 724px)/2);
  }
  .logo {
    left: calc((100vw - 724px)/2);
  }
  .headline {
    padding: 100px 0 140px;
    margin: 0;
  }
}
@media (max-width: 1199px) and (min-width: 980px) {
  .hamburger {
    right: calc((100vw - 940px)/2);
  }
  .hamburgerToggle {
    padding: 0 calc((100vw - 940px)/2);
  }
  .logo {
    left: calc((100vw - 940px)/2);
  }
  .headline {
    padding: 120px 0 160px;
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .hamburger {
    right: calc((100vw - 1170px)/2);
  }
  .hamburgerToggle {
    padding: 0 calc((100vw - 1170px)/2);
  }
  .logo {
    left: calc((100vw - 1170px)/2);
  }
  .headline {
    padding: 140px 0 200px;
    margin: 0;
  }
}